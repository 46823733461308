import config from '../../config';
import callApi from '../../util/apiCaller';
import { formatPrice, getPriceCent, getPriceFromCent } from '../../util/price';
import { unique } from '../../util/array';

import { addNotificationRequest } from '../Notification/NotificationActions';
import { getTaxRates } from '../Cart/CartActions';
import { getShippingFeeDetails } from '../Shipping/ShippingActions';
import { getProductPrice } from '../Product/ProductActions';
import { getCompanyOption } from '../Company/CompanyActions';
import { getUser } from '../User/UserActions';
import { setIsFetching, FETCH_LIMIT } from '../App/AppActions';
import { addError } from '../Error/ErrorActions';

export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDER = 'SET_ORDER';
export const REMOVE_ORDER = 'REMOVE_ORDER';

// Requests
export function getOrdersRequest(search, filters = null, sorter = { column: null, type: 'ASC' }, pager = { current: 0, size: FETCH_LIMIT }) {
    const recursiveFetch = (limit, start = 0, orders = []) => {
        return new Promise((resolve, reject) => {
            return callApi(`orders?${search ? `search=${search}&` : ''}${filters ? `${Object.keys(filters).map(filterKey => `filters[${filterKey}]=${filters[filterKey]}`).join('&')}&` : ''}${sorter ? `sortBy=${sorter.column || (typeof sorter === 'string' ? sorter : '')}&sortType=${sorter.type || 'ASC'}` : ''}&start=${start}&limit=${limit}`).then(res => {
                orders = orders.concat(res.orders);
                if(!pager && res.orders && res.orders.length >= FETCH_LIMIT) {
                    return resolve(recursiveFetch(limit, start + limit, orders));
                }
                resolve(orders);
            }).catch(err => {
                reject(err);
            });
        });
    };

    return dispatch => {
        dispatch(setIsFetching('orders'));
        return recursiveFetch((pager && pager.size) || FETCH_LIMIT, pager ? pager.current * ((pager && pager.size) || FETCH_LIMIT) : 0).then(orders => {
            dispatch(setOrders(orders || []));
            return orders;
        }).catch(err => {
            dispatch(addError(err));
            return null;
        });
    };
}

export function getOrdersByCompanyRequest(companyId, type = null) {
    return dispatch => {
        return callApi(`orders/company${companyId ? `/${companyId}` : ''}${type ? `/${type}` : ''}`).then(res => {
            res.orders && dispatch(setOrders(res.orders));
            return res.orders;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function getOrderRequest(orderId, field = null) {
    return dispatch => {
        return callApi(`order/${orderId}${field ? `/${field}` : ''}`).then(res => {
            res.order && dispatch(setOrders([res.order]));
            return res.order;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function editOrderRequest(order, options = {}) {
    return dispatch => {
        return callApi('order/edit', 'post', { order, options }).then(res => {
            res.order && dispatch(getOrderRequest(res.order._id));
            return res.order;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function createOrderRequest(order) {
    return dispatch => {
        return callApi('order/create', 'post', { order }).then(res => {
            res.order && dispatch(getOrderRequest(res.order._id));
            return res.order;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function getOrdersSummaryRequest(ordersIdsString = '', date = '', key = '', salt = '') {
    return callApi('orders/summary', 'post', { orders: ordersIdsString, date, key, salt }).then(result => {
        return result && result.orders;
    }).catch(err => {
        return null;
    });
}

export function sendOrdersSummaryRequest(orderIds, emails, date, options = {}) {
    return dispatch => {
        return callApi('orders/summary/send', 'post', { orders: orderIds, emails, date, options }).then(res => {
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function sendOrdersToCurosRequest(orderIds) {
    return dispatch => {
        return callApi('orders/curos/send', 'post', { orders: orderIds }).then(res => {
            return res.ok;
        }).catch(error => {
            dispatch(addError(error));
            return null;
        });
    };
}

export function sendOrderProformaRequest(orderId) {
    return dispatch => {
        return callApi(`order/${orderId}/proforma/send`).then(res => {
            return res.ok;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function moderateProformaRequest(orderId, status) {
    return dispatch => {
        return callApi(`/order/${orderId}/proforma/${status}`, 'post', {}).then(res => {
            res.order && dispatch(setOrder(res.order));
            return res.order;
        }).catch(error => {
            // dispatch(addError(error));
            return null;
        });
    };
}

export function addOrderNotificationRequest(order, message, values = {}, options = {}) {
    return addNotificationRequest(
        'order',
        getOrderId(order),
        message,
        values,
        null,
        {
            isHidden: !!getOrderOption(order, 'hideNotification'),
            ...options,
        },
    );
}

export function sendOrderContactRequest(orderIds, contact) {
    return dispatch => {
        return callApi('order/contact', 'post', { orders: orderIds, ...contact }).then(result => {
            return result && result.ok;
        }).catch(err => {
            return null;
        });
    };
}

// GETTERS
export function getOrders(store, type = null, statuses = null) {
    if(statuses && !Array.isArray(statuses)) {
        statuses = [statuses];
    }
    return store.orders.data.filter(order => (!type || order.type === type) && (!statuses || statuses.includes(order.status)));
}

export function getOrdersByCompany(store, companyId, statuses = null) {
    return getOrders(store, statuses).filter(order => !companyId || getOrderCompanyId(order) === companyId);
}

export function getOrdersByUser(store, userId, statuses = null) {
    return getOrders(store, statuses).filter(order => !userId || ((getUser(store, userId) || {}).companies || []).includes(getOrderCompanyId(order)));
}

export function getOrder(store, orderId) {
    return store.orders.data.find(order => order._id === orderId);
}

export function getOrderByCode(store, orderCode) {
    return store.orders.data.find(order => `${order.code}`.toLowerCase() === `${orderCode}`.toLowerCase()) || null;
}

export function getOrderId(order) {
    return order && order._id ? order._id : order;
}

export function getOrderCompanyId(order) {
    return order && order.company && order.company._id ? order.company._id : (order || {}).company;
}

export function getOrderItemsTotal(items) {
    return formatPrice(items.filter(item => item.status === 'ordered').reduce((total, item) => total + getProductPrice(item, item.quantity), 0));
}

export function getOrderTax(items) {
    return formatPrice(items.filter(item => item.status === 'ordered').reduce((total, item) => total + getProductPrice(item, item.quantity) * (item.taxRate || config.application.taxRate), 0));
}

export function getOrderTotals(order) {
    const orderItems = order.items.filter(item => item.status === 'ordered');
    const totalItems = getOrderItemsTotal(orderItems);
    const shippingFeeDetails = getShippingFeeDetails((order.shipping || {}).address, (order.shipping || {}).method, orderItems, { increasePercentage: getOrderOption(order, 'shippingExtraFeeRate') });
    const shippingFee = (shippingFeeDetails.base || 0) + (shippingFeeDetails.extraFee || 0) + (shippingFeeDetails.increase || 0);
    const orderTax = getCompanyOption(order.company, 'exemptTax') ? 0 : (getOrderTax(order.items) + (shippingFeeDetails.tax || 0));
    const totalTTC = Math.max(0, formatPrice(
        totalItems
        + shippingFee
        + (orderTax || 0),
    ));
    return {
        items: totalItems,
        shippingFee,
        includingTax: !getCompanyOption(order.company, 'exemptTax'),
        tax: orderTax,
        taxRates: unique([...getTaxRates(orderItems), shippingFeeDetails.taxRate]),
        totalTTC,
    };
}

export function hasFlagItem(item, flag) {
    return item && (item.flags || []).includes(flag);
}

export function getOrderTypes() {
    return ['order', 'proforma', 'forecast'];
}

export function getOrderStatuses(type = null) {
    if(type === 'proforma') {
        return ['pending', 'declined', 'accepted'];
    }
    if(type === 'forecast') {
        return ['asked', 'onpreparation', 'done', 'canceled'];
    }
    return ['pendingpayment', 'onvalidation', 'onpreparation', 'onshipment', 'shipped', 'done', 'canceled'];
}

export function getAvailableFlags() {
    return ['subtotal', 'packFill', 'packCount', 'paid'];
}

export function getOrderDocumentTypes() {
    return ['invoice', 'creditmemo', 'shipment', 'delivery'];
}

export function getOrderOption(order, option) {
    return (order && order.options && order.options[option]) || '';
}

export function getOrderStatusColor(status) {
    switch(status) {
        case 'pendingpayment':
        case 'asked':
        case 'pending':
            return 'warning';

        case 'onvalidation':
            return 'info';

        case 'onpreparation':
            return 'secondary';

        case 'onshipment':
        case 'shipped':
            return 'success';

        case 'done':
        case 'accepted':
            return 'light';

        case 'canceled':
        case 'declined':
            return 'danger';

        case 'archived':
        default:
            return 'primary';
    }
}

export function getPaymentStatusColor(status) {
    switch(status) {
        case 'pending':
            return 'warning';

        case 'failed':
            return 'danger';

        case 'complete':
            return 'success';

        default:
            return 'secondary';
    }
}

// Actions
export function setOrders(orders) {
    return {
        type: SET_ORDERS,
        orders,
    };
}

export function setOrder(order) {
    return {
        type: SET_ORDER,
        order,
    };
}

export function removeOrder(orderId) {
    return {
        type: REMOVE_ORDER,
        orderId,
    };
}
